<template>
  <div>
    <el-dialog title="新增模板组卷"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               :width="width"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="80px">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="试卷名称">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_name" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="试卷科目"
                          prop="subjectId">
              <el-select v-model="form.subjectId"
                         style="width:100%"
                         @change="subjectChage"
                         placeholder="选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="试卷地区"
                          prop="paper_district_id">
              <el-select v-model="form.paper_district_id"
                         style="width:100%"
                         filterable
                         :no-data-text="this.form.subjectId?'无数据':'先选择科目'"
                         placeholder="选择">
                <el-option v-for="item in districtList"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="8">
            <el-form-item label="指定年级"
                          prop="given_garde">
              <el-select v-model="form.given_garde"
                         clearable
                         style="width:100%"
                         @change="gradeChange"
                         placeholder="选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->

          <el-col :span="12">
            <el-form-item label="指定班级"
                          prop="given_class">
              <el-select v-model="form.given_class"
                         clearable
                         filterable
                         @change="classChange"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in classList"
                           :key="item.id"
                           :label="item.class_name "
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="开始时间">
              <el-date-picker v-model="form.paper_time"
                              style="width:100%"
                              type="datetime"
                              @focus="$forbid"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="截止时间">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              @focus="$forbid"
                              type="datetime"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>

        </el-row>
        <p>
          说明：
        </p>
        <p>
          1.试卷可以定时开始训练，也可以不选时间，这样学生任何时间都可以训练；
        </p>
        <p>
          2.组的试卷必须提交才能导出或提供给学生做题。
        </p>

        <el-row :gutter="10"
                style="text-align:center;margin-top:20px">
          <el-col :span="24">
            <el-button type="primary"
                       @click="toInfo">提交</el-button>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
import { getUserSubject } from '@/api/subject.js'
export default {
  data () {
    return {
      form: {},
      width: '640px',
      showList: [],
      dialogVisible: false,
      testingList: [],
      subjectList: [],
      gradeList: [],
      classList: [],
      districtList: [],

      // ],
      rules: {
        paper_name: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        subjectId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],


      },

    }
  },
  mounted () {
    this.getSubjectList()
  },

  methods: {
    // getGradeAndClass () {
    //   this.$http({
    //     url: '/api/v1/public/subject_get_grade_and_class',
    //     method: 'get',
    //     params: {
    //       subject_id: this.form.subjectId
    //     }
    //   }).then(res => {
    //     console.log('resaaaa', res)
    //     this.classList = res.data.class_data
    //     this.gradeList = res.data.grade_data
    //   })
    // },


    toInfo () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.subject_id = form.subjectId
        delete form.subjectId

        this.$http({
          url: '/api/v1/combination/rule_create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.$router.push('/testPapercomposition/templatePaper/addDetails?edu_paper_id=' + res.data.edu_paper_id)
        })
      })
    },
    getDirList () {
      this.$http({
        url: '/api/v1/combination/rule_subject_district',
        method: 'get',
        params: {
          subject_id: this.form.subjectId
        }
      }).then(res => {
        this.districtList = Object.values(res.data)
        if (this.form.paper_district_id) {
          let len = this.districtList.filter(item => item.paper_district_id == this.form.paper_district_id)
          if (len && len.length == 0) {
            this.$set(this.form, 'paper_district_id', '')
          }
        }
      })
    },


    classChange () {

    },
    subjectChage () {
      this.getDirList()
    },

    getClass () {
      this.$http({
        url: '/api/v1/special/tc_lead_class',
        method: 'get',
        params: {
          grade_id: 1,
        }
      }).then(res => {
        this.classList = res.data
      })
    },
    getList () {
      this.getDirList()
      this.getClass()
    },
    async getSubjectList () {
      const { data } = await getUserSubject()
      this.subjectList = data.list
    },


    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  font-size: 14px;
  div {
    margin-left: 15px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>